<template>
  <div class="password-page">
    <div class="">
      <a-spin :spinning="loadingForm">
        <a-form
          ref="formRef"
          :rules="rules"
          :model="form"
          class="login-form"
          @finish="handleSubmit"
        >
          <a-form-item name="user_password" label="登录密码">
            <a-input
              size="large"
              v-model:value="form.user_password"
              type="password"
              placeholder="请输入密码"
            >
            </a-input>
          </a-form-item>
          <a-form-item name="user_repeat_password" label="确认密码">
            <a-input
              size="large"
              v-model:value="form.user_repeat_password"
              type="password"
              placeholder="请确认密码"
            >
            </a-input>
          </a-form-item>
          <a-form-item class="login-button-box">
            <a-button
              block
              size="large"
              type="primary"
              html-type="submit"
              class="btn"
              :loading="loadingBtn"
              @click="handleSubmit"
            >
              确认修改
            </a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  computed,
} from "@vue/runtime-core";
import { message, notification, Form } from "ant-design-vue";
// import ls from "@/utils/local-storage";
import { sendCodeSms, updateUserPasswordData } from "@/api/main.js";
import { getUserId } from "@/utils/storeData.js";

export default defineComponent({
  name: "Password",
  components: {},
  setup() {
    const formRef = ref();
    const pageData = reactive({
      form: {
        user_password: null,
        user_repeat_password: null,
      },
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
    });
    let validatePass = async (rule, value) => {
      let regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/;
      if (!value) {
        return Promise.reject("请输入新密码");
      } else if (!regex.test(value)) {
        return Promise.reject(
          "密码需满足至少8个字符，且需同时包含字母、数字和符号！"
        );
      } else {
        return Promise.resolve();
      }
    };
    let validatePass2 = async (rule, value) => {
      if (!value) {
        return Promise.reject("请再次输入新密码");
      } else if (value !== pageData.form.user_password) {
        return Promise.reject("两次密码不一致");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      user_password: [
        { required: true, validator: validatePass, trigger: "change" },
      ],
      user_repeat_password: [
        { required: true, validator: validatePass2, trigger: "change" },
      ],
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      if (pageData.form.user_password != pageData.form.user_repeat_password) {
        return message.error("两次输入的密码不一致！");
      }
      pageData.loadingBtn = true;
      pageData.loadingForm = true;
      formRef.value
        .validate()
        .then((res) => {
          console.log(res);
          updateUserPasswordData({
            user_id: pageData.user_id,
            user_password: res.user_password,
          }).then((res) => {
            if (res.code == 200) {
              message.success("提交成功");
            } else {
              message.error(res.msg);
            }
          });
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };

    return {
      ...toRefs(pageData),
      handleSubmit,
      formRef,
      rules,
    };
  },
});
</script>

<style lang="less" scoped>
.password-page {
  width: 600px;
  padding-top: 30px;
}
</style>
