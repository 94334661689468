<template>
  <div class="ucenter-activity">
    <a-spin :spinning="loading">
      <div>
        <a-empty
          v-if="activity_apply.length == 0"
          description="暂无活动"
          style="margin-top: 100px"
        ></a-empty>
        <div v-else class="activity-box-main">
          <template v-for="(item, index) in activity_apply" :key="index">
            <div
              class="activity-item flex flex-between"
              @click="goToActivityDetail(item)"
            >
              <div class="flex">
                <div class="activity-image-wrap">
                  <img
                    :src="item.activity_image"
                    class="activity-image"
                    alt=""
                  />
                  <span
                    :class="{
                      status: true,
                      'status-cancel': item.apply_status == -1
                    }"
                    >{{ item.apply_status == 1 ? "已报名" : "已取消" }}</span
                  >
                </div>
                <div class="activity-apply-info">
                  <div class="activity-title">{{ item.activity_title }}</div>
                  <div>
                    <div class="font-16 text-gray mb-10">
                      {{ item.apply_name }}&nbsp;&nbsp;{{ item.apply_phone }}
                    </div>
                    <div class="activity-time">
                      报名时间：{{ item.apply_create_time }}
                    </div>
                  </div>
                </div>
              </div>
              <right-outlined />
            </div>
          </template>
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="15"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['15', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getActivityApplyList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import { RightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "UcenterActivity",
  components: {
    RightOutlined
  },
  setup() {
    const store = useStore();

    const pageData = reactive({
      activity_apply: [],
      user_id: computed(() => getUserId()),
      page: 1,
      limit: 15,
      total: 0,
      loading: false
    });
    const getActivityApplyListFun = () => {
      pageData.loading = true;
      getActivityApplyList({
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.activity_apply = res.data.activity_apply;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goToActivityDetail = _item => {
      url.navigateTo("/activity/applyDetail", {
        t: encode(`${_item.apply_id},${_item.activity_id}`)
      });
    };
    watch(
      [() => pageData.page, () => pageData.limit],
      val => {
        getActivityApplyListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      goToActivityDetail,
      onShowSizeChange
    };
  }
});
</script>
<style lang="less" scoped>
.ucenter-activity {
  .activity-box-main {
    .activity-item {
      width: 100%;
      height: 193px;
      border-bottom: 1px solid #dfdfdf;
      cursor: pointer;
      .activity-image-wrap {
        position: relative;
        width: 181px;
        height: 131px;
        overflow: hidden;
        border-radius: 10px;
        margin-right: 30px;
        .activity-image {
          width: 181px;
          height: 131px;
          background-color: #1276cb;
        }
        .status {
          position: absolute;
          left: 0;
          top: 0;
          width: 64px;
          height: 23px;
          line-height: 23px;
          background-color: #d63030;
          text-align: center;
          color: #ffffff;
          font-size: 14px;
          border-radius: 10px 0 10px 0;
        }
        .status-cancel {
          background-color: #afafaf;
        }
      }
      .activity-apply-info {
        height: 131px;
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .activity-title {
        color: #212531;
        font-size: 20px;
        font-weight: bold;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .activity-time {
        color: #212531;
        opacity: 0.7;
        font-size: 16px;
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
