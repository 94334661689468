<template>
  <div class="ucenter-enterprise-page">
    <a-empty v-if="!isHasEnterprise" description="暂无入驻"></a-empty>
    <a-spin v-else :spinning="loading">
      <div v-if="data">
        <div class="flex flex-between info">
          <div class="info-title">企业名称</div>
          <div class="info-content">{{ data.enterprise_name }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">企业LOGO</div>
          <div class="info-content">
            <a-image
              :width="50"
              :height="50"
              :src="data.enterprise_logo"
              class="flow-img"
            />
          </div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">统一社会信用代码</div>
          <div class="info-content">{{ data.enterprise_usci }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">企业法人</div>
          <div class="info-content">{{ data.enterprise_legal_person }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">企业人数</div>
          <div class="info-content">{{ data.enterprise_people_num }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">企业联系人</div>
          <div class="info-content">{{ data.enterprise_contact_name }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">联系人电话</div>
          <div class="info-content">{{ data.enterprise_contact_phone }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">注册地址</div>
          <div class="info-content">{{ data.enterprise_address }}</div>
        </div>
        <div class="flex flex-between info">
          <div class="info-title">入驻日期</div>
          <div class="info-content">{{ data.enterprise_enter_time }}</div>
        </div>
        <div
          class="flex flex-between info"
          style="padding: 10px 0; height: auto"
        >
          <div class="info-title">企业简介</div>
          <div class="info-content">{{ data.enterprise_introduction }}</div>
        </div>
        <!-- <div class="flex flex-between info">
          <div class="info-title">营业执照</div>
          <div class="info-content">
            <a-image
              v-for="(img, index2) in toArr(data.enterprise_license)"
              :key="index2"
              :width="50"
              :height="50"
              :src="img"
            />
          </div>
        </div> -->
        <div class="flex handle-box">
          <a-button
            class="ucenter-btn"
            v-if="
              data.enterprise_enter_status == 4 &&
              (data.enterprise_leave_status == 0 ||
                data.enterprise_leave_status == -1) &&
              userData?.enterprise_user?.enterprise_user_ismanager == 1
            "
            @click="updateEnterpriseLeaveStatusDataFun"
            >申请退园</a-button
          >
        </div>
      </div>
      <leave-popup
        v-model:visible="visible"
        :enterpriseId="data?.enterprise_id"
        @success="sendSuccess"
      ></leave-popup>
    </a-spin>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch
} from "@vue/runtime-core";
import { updateEnterpriseLeaveStatusData } from "@/api/main.js";
import { message } from "ant-design-vue";
import { getUserData, getUserId } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import url from "@/utils/url.js";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import LeavePopup from "./leavePopup.vue";

export default defineComponent({
  name: "UcenterEnterprise",
  components: {
    LeavePopup
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {};
      }
    },
    isHasEnterprise: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update"],
  setup(props, { emit }) {
    const pageData = reactive({
      park: null,
      loading: false,
      userData: computed(() => getUserData()),
      user_id: computed(() => getUserId()),
      visible: false
    });
    const toArr = str => {
      if (str) {
        return str.split(",");
      }
    };
    // 申请退园
    const updateEnterpriseLeaveStatusDataFun = () => {
      pageData.visible = true;
      // Modal.confirm({
      //   title: "提示",
      //   icon: createVNode(ExclamationCircleOutlined),
      //   content: "确定退园？",
      //   okText: "确认",
      //   cancelText: "取消",
      //   onOk() {
      //     updateEnterpriseLeaveStatusData({
      //       user_id: pageData.user_id,
      //       enterprise_id: props.data?.enterprise_id
      //     })
      //       .then(res => {
      //         if (res.code == 200) {
      //           message.success("操作成功");
      //           emit("update", props.data?.enterprise_id);
      //         } else {
      //           message.error(res.msg);
      //         }
      //       })
      //       .catch(res => {
      //         console.log(res);
      //       });
      //   }
      // });
    };
    const sendSuccess = () => {
      emit("update", props.data?.enterprise_id);
    };

    return {
      ...toRefs(pageData),
      toArr,
      updateEnterpriseLeaveStatusDataFun,
      sendSuccess
    };
  }
});
</script>

<style lang="less" scoped>
.ucenter-enterprise-page {
  .info {
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    .info-title {
      color: #212531;
      opacity: 0.7;
      font-size: 18px;
    }
    .info-content {
      color: #212531;
      font-size: 18px;
      width: 80%;
      text-align: right;
      span {
        width: 70px;
        height: 31px;
        background-color: #1276cb;
        border-radius: 15px;
        color: #ffffff;
        padding: 0 10px;
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .handle-box {
    justify-content: center;
    margin: 40px 0;
    .ucenter-btn {
      width: 400px;
      height: 60px;
      border-radius: 30px;
      font-size: 18px;
      margin: 0 20px;
    }
    .active-btn {
      background-color: #1276cb;
    }
  }
}
</style>
