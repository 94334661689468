<template>
  <a-modal
    v-model:visible="popupVisible"
    width="1060px"
    :footer="null"
    :maskClosable="false"
    :closable="closeable"
    wrapClassName="leave-send-box"
    @clsoe="closeModal"
  >
    <a-spin :spinning="loadingForm">
      <div class="leave-modal-title">
        <div class="title-cn">申请退园</div>
      </div>
      <a-form :model="form" ref="formRef" :colon="colon" @finish="handleSubmit">
        <div class="leave-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="24">
              <div class="font-18 mb-20">
                上传电子版（图片形式）的《唐山人力资源服务产业园退出申请表》后，提交申请
              </div>
              <attachment :data="file"></attachment>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="enterprise_leave_material"
                :rules="{ required: true, message: '请上传' }"
              >
                <upload-image
                  v-model:imageList="form.enterprise_leave_material"
                  :imageCount="9"
                  :couldRename="false"
                  @change="getImageStatus('enterprise_leave_material')"
                ></upload-image>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <a-form-item class="">
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            class="send-btn"
            :loading="loadingBtn"
            @click="handleSubmit"
          >
            确认提交
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import { updateEnterpriseLeaveStatusData } from "@/api/main.js";
import { uploadFile } from "@/utils/ali-oss/upload.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import uploadImage from "@/components/upload.vue";
import Attachment from "@/components/attachment.vue";
import { message } from "ant-design-vue";
export default defineComponent({
  name: "leavePopup",
  components: {
    uploadImage,
    Attachment
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    enterpriseId: {
      type: String,
      default: null
    }
  },
  emits: ["update:visible", "success"],
  setup(props, { emit }) {
    const formRef = ref();
    const pageData = reactive({
      popupVisible: false,
      colon: false,
      autosize: false,
      form: {
        enterprise_leave_material: null
      },
      previewVisible: false,
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      file: [
        {
          attachment_name: "唐山人力资源服务产业园退出申请表.docx",
          attachment_src:
            "https://cyyq.tsrc.net.cn/static/sheet/唐山人力资源服务产业园退出申请表.docx"
        }
      ],
      enterprise_id: null
    });
    const closeModal = () => {
      pageData.popupVisible = false;
    };

    const handleSubmit = e => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(res => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          let L={ leave_sheet:res.enterprise_leave_material}
          let data = {
            enterprise_leave_material: JSON.stringify(L),
            user_id: pageData.user_id,
            enterprise_id: pageData.enterprise_id
          };
          console.log("data", data);
          updateEnterpriseLeaveStatusData(data)
            .then(res => {
              if (res.code == 200) {
                emit("success");
                closeModal();
              } else {
                message.error(res.msg);
              }
            })
            .catch(res => {
              console.log(res);
            });
        })
        .catch(res => {
          console.log("res", res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const getImageStatus = e => {
      formRef.value.clearValidate(e);
    };
    watch(
      () => props.visible,
      val => {
        if (val) {
          pageData.popupVisible = true;
        }
      },
      { immediate: true }
    );
    watch(
      () => props.enterpriseId,
      val => {
        if (val) {
          pageData.enterprise_id = props.enterpriseId;
        }
      },
      { immediate: true }
    );

    watch(
      () => pageData.popupVisible,
      val => {
        emit("update:visible", val);
      },
      {
        immediate: true
      }
    );
    return {
      ...toRefs(pageData),
      closeModal,
      formRef,
      handleSubmit,
      getImageStatus
    };
  }
});
</script>
<style lang="less">
.leave-send-box {
  .ant-modal-content {
    width: 1060px;
    // height: 640px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
    .leave-modal-title {
      text-align: center;
      color: #212531;
      font-weight: bold;
      font-size: 30px;
      margin-top: 30px;
    }
    .leave-wrap {
      width: 960px;
      height: 420px;
      background-color: #ffffff;
      border-radius: 10px;
      margin: 40px auto;
      padding: 20px;
      box-sizing: border-box;
    }
    .send-btn {
      display: block;
      width: 400px;
      height: 60px;
      background-color: #1276cb;
      border-radius: 30px;
      font-size: 20px;
      margin: 0 auto 20px auto;
    }
  }
}
</style>
