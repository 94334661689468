<template>
  <div class="mine-page">
    <div class="mine-banner">
      <div class="page-main mine-info flex">
        <img
          v-if="userData.user_avatar"
          :src="userData.user_avatar"
          class="avatar"
        />
        <img v-else src="~@/assets/avatar.jpg" class="avatar" />
        <div>
          <div>
            <span class="font-22 mr-20">{{ userData.user_nickname }}</span>
            <span class="font-18 text-gray">{{ userData.user_phone }}</span>
          </div>
          <div class="mt-10">
            <span
              :class="{
                'mine-label': true,
                'mine-label-active': enterprise_list.length > 0,
              }"
            >
              {{ enterprise_list.length > 0 ? "已入驻" : "未入驻" }}</span
            >
            <span
              class="mine-label ml-20"
              v-if="enterprise_list.length > 0 && enterprise"
              @click="
                () => {
                  visible = true;
                }
              "
              >{{ enterprise.enterprise_name }} <SwapOutlined
            /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="flex" style="align-items: unset">
        <div class="mine-left">
          <div class="mine-menu">
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 0 }"
              @click="changeMenu(0)"
            >
              <div class="mine-menu-text">
                <div class="flex">
                  <img src="~@/assets/ucenter-icon-1.png" alt="" />
                  入驻信息
                </div>

                <img
                  v-if="menuActive == 0"
                  src="~@/assets/right-activity.png"
                  alt=""
                  class="icon"
                />
                <img v-else src="~@/assets/right.png" alt="" class="icon" />
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 1 }"
              @click="changeMenu(1)"
            >
              <div class="mine-menu-text">
                <div class="flex">
                  <img src="~@/assets/ucenter-icon-2.png" alt="" /> 我的活动
                </div>
                <img
                  v-if="menuActive == 1"
                  src="~@/assets/right-activity.png"
                  alt=""
                  class="icon"
                />
                <img v-else src="~@/assets/right.png" alt="" class="icon" />
              </div>
            </div>
            <!-- <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 2 }"
              @click="changeMenu(2)"
            >
              <div class="mine-menu-text">
                <div class="flex">
                  <img src="~@/assets/ucenter-icon-3.png" alt="" />
                  我的帖子
                </div>

                <img
                  v-if="menuActive == 2"
                  src="~@/assets/right-activity.png"
                  alt=""
                  class="icon"
                />
                <img v-else src="~@/assets/right.png" alt="" class="icon" />
              </div>
            </div> -->
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 3 }"
              @click="changeMenu(3)"
            >
              <div class="mine-menu-text">
                <div class="flex">
                  <img src="~@/assets/ucenter-icon-5.png" alt="" />
                  修改密码
                </div>

                <img
                  v-if="menuActive == 3"
                  src="~@/assets/right-activity.png"
                  alt=""
                  class="icon"
                />
                <img v-else src="~@/assets/right.png" alt="" class="icon" />
              </div>
            </div>
            <div
              :class="{ 'menu-title': true, 'menu-active': menuActive == 4 }"
              @click="changeMenu(4)"
            >
              <div class="mine-menu-text">
                <div class="flex">
                  <img src="~@/assets/ucenter-icon-4.png" alt="" />
                  退出登录
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mine-right bg-white box-shadow-1">
          <enterprise
            v-if="menuActive == 0"
            :data="enterprise"
            :isHasEnterprise="isHasEnterprise"
            @update="changeEnterStatus"
          ></enterprise>
          <activity v-if="menuActive == 1"></activity>
          <post v-if="menuActive == 2"></post>
          <password v-if="menuActive == 3"></password>
        </div>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      :closable="false"
      width="1060px"
      :footer="null"
      :maskClosable="false"
      wrapClassName="ucenter-enterprise-box"
    >
      <div class="ucenter-enterprise-modal-title">
        <div class="title-cn">选择企业</div>
        <div class="title-en">CHOOSE A Enterprise</div>
      </div>
      <div class="ucenter-enterprise-wrap flex">
        <template v-for="(item, index) in enterprise_list" :key="index">
          <div
            :class="{
              'ucenter-enterprise-item': true,
              'ucenter-enterprise-active':
                item.enterprise_id == selectedEnterprise.enterprise_id,
            }"
            @click="chooseEnterprise(item)"
          >
            {{ item.enterprise_name }}
          </div>
        </template>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
} from "@vue/runtime-core";
import Enterprise from "./components/enterprise.vue";
import Activity from "./components/activity.vue";
import Post from "./components/post.vue";
import Password from "./components/password.vue";
import { message, Modal, notification } from "ant-design-vue";
import { getUserData, getUserId } from "@/utils/storeData.js";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import url from "@/utils/url.js";
import {
  getEnterpriseList2,
  getEnterpriseData,
  updateUserEnterpriseIdData,
} from "@/api/main.js";
import { SwapOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  name: "Ucenter",
  components: {
    Enterprise,
    Activity,
    Post,
    SwapOutlined,
    Password,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const pageData = reactive({
      userData: null,
      menuActive: 0,
      enterprise: null,
      enterprise_list: [],
      visible: false,
      selectedEnterprise: {},
      isHasEnterprise: false,
    });

    pageData.userData = computed(() => getUserData());
    pageData.user_id = computed(() => getUserId());
    // console.log("userData", pageData.userData);
    watch(
      () => route.query,
      (val) => {
        if (route.path == "/ucenter") {
          if (val?.t) {
            let query = val?.t.split(",");
            // console.log('query[0]',query[0]);
            if (query[0]) {
              pageData.menuActive = query[0];
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const changeMenu = (index) => {
      if (index == 4) {
        // message.error("退出登录");
        store
          .dispatch("LOGOUT")
          .then(() => {
            notification.success({
              message: "退出成功",
              duration: 1.5,
              description: "正在跳转页面中，请稍后……",
              onClose: () => {},
            });
            url.navigateTo("/index");
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        pageData.menuActive = index;
      }
    };
    //获取企业列表
    const getEnterpriseListFun = () => {
      getEnterpriseList2({
        park_id: pageData.userData?.user_park_id,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.enterprise_list = res.data.enterprise;
            pageData.isHasEnterprise =
              res.data.enterprise.length > 0 ? true : false;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    getEnterpriseListFun();
    //获取企业详情
    const getEnterpriseDataFun = (enterprise_id) => {
      getEnterpriseData({
        enterprise_id: enterprise_id,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.enterprise = res.data.enterprise;
            pageData.selectedEnterprise.enterprise_id =
              res.data.enterprise.enterprise_id;
            pageData.selectedEnterprise.enterprise_name =
              res.data.enterprise.enterprise_name;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    getEnterpriseDataFun(pageData.userData?.user_enterprise_id);
    const updateUserEnterpriseIdDataFun = (user_enterprise_id) => {
      updateUserEnterpriseIdData({
        user_enterprise_id: user_enterprise_id,
        user_id: pageData.user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            getEnterpriseDataFun(user_enterprise_id);
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        });
    };
    const chooseEnterprise = (_item) => {
      pageData.selectedEnterprise = _item;
      pageData.visible = false;
      updateUserEnterpriseIdDataFun(_item.enterprise_id);
    };
    const changeEnterStatus = (e) => {
      getEnterpriseDataFun(e);
    };
    return {
      ...toRefs(pageData),
      changeMenu,
      chooseEnterprise,
      changeEnterStatus,
    };
  },
});
</script>

<style lang="less">
.mine-page {
  background-image: linear-gradient(0deg, #f3f5f8 0%, #1276cb 100%);
  padding-top: 30px;
  padding-bottom: 150px;
  .ant-empty {
    margin-top: 100px;
  }
  .mine-info {
    width: 1200px;
    height: 120px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 0 30px;
    box-sizing: border-box;
    .avatar {
      width: 80px;
      height: 80px;
      background-color: #1f7acb;
      border-radius: 80px;
      margin-right: 20px;
    }
    .mine-label {
      background-color: rgba(124, 124, 124, 0.6);
      border-radius: 11px;
      color: #fff;
      padding: 0 20px;
      font-size: 14px;
      cursor: pointer;
    }
    .mine-label-active {
      background-color: #1276cb;
    }
  }

  .mine-left {
    width: 180px;
    height: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    margin-right: 20px;
    .mine-menu {
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      .menu-title {
        height: 80px;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dfdfdf;
        cursor: pointer;
        .mine-menu-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          width: 100%;
          img {
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 12px;
            overflow: hidden;
          }
          .icon {
            width: 5px;
            height: 8px;
            margin-right: 0;
          }
        }
      }
      .menu-active {
        color: #1276cb;
        position: relative;
      }
    }
  }

  .mine-right {
    border-radius: 8px;
    padding: 0 30px;
    width: calc(100% - 180px);
  }
}
.ucenter-enterprise-box {
  .ant-modal-content {
    width: 1060px;
    padding-bottom: 30px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
    .ucenter-enterprise-modal-title {
      text-align: center;
      margin-top: 26px;
      .title-cn {
        color: #212531;
        font-size: 30px;
        font-weight: bold;
      }
      .title-en {
        color: #212531;
        opacity: 0.6;
        font-size: 14px;
      }
    }
    .ucenter-enterprise-wrap {
      flex-wrap: wrap;
      padding: 0 30px;
      .ucenter-enterprise-item {
        padding: 0 20px;
        height: 50px;
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 0px 0px 27px 0px #f2f6f9;
        border-radius: 25px;
        color: #212531;
        font-size: 20px;
        text-align: center;
        line-height: 50px;
        margin: 30px 20px 0 20px;
        cursor: pointer;
      }
      .ucenter-enterprise-active {
        background-color: #1276cb;
        box-shadow: 0px 0px 27px 0px #f2f6f9;
        color: #fff;
      }
    }
  }
}
</style>
