<template>
  <div class="ucenter-post">
    <a-spin :spinning="loading">
      <div>
        <a-empty
          v-if="post.length == 0"
          description="暂无帖子"
          style="margin-top: 100px"
        ></a-empty>
        <div v-else class="post-box-main">
          <template v-for="(item, index) in post" :key="index">
            <div class="post-item flex flex-between">
              <div class="post-left" @click="goToPostDetail(item)">
                <div class="flex mb-10" style="width:780px">
                  <span
                    :class="{
                      'post-type': true,
                      'post-type-active': item.father_post_id != 0
                    }"
                    style="flex-shrink:0"
                    >{{ item.father_post_id == 0 ? "发布" : "评论" }}</span
                  >
                  <span class="font-20 font-bold">{{ item.post_title }}</span>
                </div>
                <div class="font-16 text-gray">{{ item.post_time }}</div>
              </div>
              <div class="handle-box flex">
                <div class="mr-20 text-blue font-16" @click="updatePost(item)">
                  <EditOutlined />修改
                </div>
                <div
                  class="text-red font-16"
                  @click="updatePostStatusDataFun(item)"
                >
                  删除
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="15"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['15', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
    </a-spin>
    <post-popup
      v-model:visible="visible"
      :postId="post_id"
      :fatherPostId="father_post_id"
      @success="sendSuccess"
    ></post-popup>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  createVNode
} from "@vue/runtime-core";
import { getUserPostList, updatePostStatusData } from "@/api/main.js";
import { message, Modal } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import PostPopup from "./postPopup.vue";
export default defineComponent({
  name: "UcenterPost",
  components: {
    EditOutlined,
    PostPopup
  },
  setup() {
    const store = useStore();

    const pageData = reactive({
      post: [],
      user_id: computed(() => getUserId()),
      page: 1,
      limit: 15,
      total: 0,
      loading: false,
      visible: false,
      post_id: null,
      father_post_id: null
    });
    const getUserPostListFun = () => {
      pageData.loading = true;
      getUserPostList({
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.post = res.data.post;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goToPostDetail = _item => {
      if (_item.father_post_id) {
        url.navigateTo("/post/detail", {
          t: encode(`${_item.father_post_id}`)
        });
      } else {
        url.navigateTo("/post/detail", {
          t: encode(`${_item.post_id}`)
        });
      }
    };
    const updatePostStatusDataFun = item => {
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: "确认删除？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          updatePostStatusData({
            post_id: item.post_id,
            user_id: pageData.user_id,
            post_status: -1
          })
            .then(res => {
              if (res.code == 200) {
                message.success("操作成功");
                getUserPostListFun();
              } else {
                message.error(res.msg);
              }
            })
            .catch(res => {
              console.log(res);
            });
        }
      });
    };
    const sendSuccess = () => {
      getUserPostListFun();
    };
    const updatePost = item => {
      pageData.visible = true;
      pageData.post_id = item.post_id;
      pageData.father_post_id = item.father_post_id;
    };
    watch(
      [() => pageData.page, () => pageData.limit],
      val => {
        getUserPostListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      ...toRefs(pageData),
      onShowSizeChange,
      goToPostDetail,
      updatePostStatusDataFun,
      sendSuccess,
      updatePost
    };
  }
});
</script>
<style lang="less" scoped>
.ucenter-post {
  .post-box-main {
    .post-item {
      width: 100%;
      border-bottom: 1px solid #dfdfdf;
      padding: 20px 0;
      align-items: flex-end;
      .post-type {
        width: 50px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #1276cb;
        color: #fff;
        border-radius: 10px;
        margin-right: 10px;
      }
      .post-type-active {
        background-color: #f3ab57;
      }
      .post-title {
        color: #212531;
        font-size: 20px;
        font-weight: bold;
        width: 500px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .post-time {
        color: #212531;
        opacity: 0.7;
        font-size: 16px;
      }
      .handle-box {
        > div {
          cursor: pointer;
        }
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
