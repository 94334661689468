<template>
  <a-modal
    v-model:visible="popupVisible"
    width="1060px"
    :footer="null"
    :maskClosable="false"
    :closable="closeable"
    wrapClassName="post-send-box"
    @clsoe="closeModal"
  >
    <a-spin :spinning="loadingForm">
      <div class="post-modal-title">
        <div class="title-cn">{{ fatherPostId ? "修改评论" : "修改帖子" }}</div>
      </div>
      <a-form :model="form" ref="formRef" :colon="colon" @finish="handleSubmit">
        <div class="post-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="12" v-if="!fatherPostId">
              <a-form-item name="post_location_id" label="发布位置">
                <a-select v-model:value="form.post_location_id" size="large">
                  <a-select-option v-if="userData?.user_ismanager" :value="1"
                    >管理互动</a-select-option
                  >
                  <a-select-option :value="2">平台互动</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="!fatherPostId">
              <a-form-item name="post_type_id" label="帖子类型">
                <a-select v-model:value="form.post_type_id" size="large">
                  <a-select-option
                    v-for="(item, index) in post_type"
                    :value="item.post_type_id"
                    :key="index"
                    >{{ item.post_type_name }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="post_title"
                :rules="{ required: true, message: '请输入评论内容' }"
              >
                <a-textarea
                  size="large"
                  v-model:value="form.post_title"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                  placeholder="输入内容......"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item name="post_image">
                <upload-image
                  v-model:imageList="form.post_image"
                  :imageCount="9"
                  :couldRename="false"
                  @change="getImageStatus('post_image')"
                ></upload-image>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <a-form-item class="">
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            class="send-btn"
            :loading="loadingBtn"
            @click="handleSubmit"
          >
            发布
          </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import { getPostTypeList, setPostData, getPostData } from "@/api/main.js";
import { uploadFile } from "@/utils/ali-oss/upload.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import uploadImage from "@/components/upload.vue";

export default defineComponent({
  name: "postPopup",
  components: {
    uploadImage
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    postData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    postId: {
      type: String,
      default: null
    },
    fatherPostId: {
      type: Number,
      default: 0
    }
  },
  emits: ["update:visible", "success"],
  setup(props, { emit }) {
    const formRef = ref();
    const pageData = reactive({
      popupVisible: false,
      colon: false,
      autosize: false,
      post_type: [],
      form: {
        post_location_id: 2,
        post_type_id: null,
        post_title: null,
        post_image: null
      },
      previewVisible: false,
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      post_id: null,
      father_post_id: 0
    });
    const closeModal = () => {
      pageData.popupVisible = false;
    };
    const getPostTypeListFun = () => {
      getPostTypeList()
        .then(res => {
          if (res.code == 200) {
            pageData.post_type = res.data.post_type;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };
    getPostTypeListFun();
    const getPostDataFun = () => {
      getPostData({
        post_id: pageData.post_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.form = res.data.post;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        });
    };

    const handleSubmit = e => {
      e.preventDefault();
      formRef.value
        .validate()
        .then(res => {
          let data = {
            post_id: pageData.post_id,
            user_id: pageData.user_id,
            father_post_id: pageData.father_post_id,
            park_id: pageData.userData?.user_park_id,
            post_location_id: pageData.form.post_location_id,
            post_type_id: pageData.form.post_type_id,
            post_title: pageData.form.post_title,
            post_image: pageData.form.post_image
          };

          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          setPostData(data).then(res => {
            emit("success");
            closeModal();
          });
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    watch(
      () => props.visible,
      val => {
        if (val) {
          pageData.popupVisible = true;
        }
      },
      { immediate: true }
    );
    watch(
      () => props.postId,
      val => {
        if (val) {
          pageData.post_id = props.postId;
          getPostDataFun();
        }
      },
      { immediate: true }
    );
    watch(
      () => props.fatherPostId,
      val => {
        if (val) {
          pageData.father_post_id = props.fatherPostId;
        }
      },
      { immediate: true }
    );
    watch(
      () => pageData.popupVisible,
      val => {
        emit("update:visible", val);
      },
      {
        immediate: true
      }
    );
    const getImageStatus = e => {
      formRef.value.clearValidate(e);
    };
    return {
      ...toRefs(pageData),
      closeModal,
      formRef,
      handleSubmit,
      getImageStatus
    };
  }
});
</script>
<style lang="less">
.post-send-box {
  .ant-modal-content {
    width: 1060px;
    // height: 640px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
    .post-modal-title {
      text-align: center;
      color: #212531;
      font-weight: bold;
      font-size: 30px;
      margin-top: 30px;
    }
    .post-wrap {
      width: 960px;
      height: 360px;
      background-color: #ffffff;
      border-radius: 10px;
      margin: 40px auto;
      padding: 20px;
      box-sizing: border-box;
    }
    .send-btn {
      display: block;
      width: 400px;
      height: 60px;
      background-color: #1276cb;
      border-radius: 30px;
      font-size: 20px;
      margin: 0 auto 20px auto;
    }
  }
}
</style>
